import {
  WIDGET_TYPE,
  BUTTONS_STYLE,
  HORIZONTAL_IMAGE_POSITION,
  IMAGE_RATIO,
  IMAGE_SCALING,
  LIST_IMAGE_LAYOUT,
  LIST_LAYOUT,
  LOCATION_AND_DATE_FORMAT,
  VERTICAL_IMAGE_POSITION,
  WIDGET_LAYOUT,
} from '@wix/wix-events-commons-statics'
import {RIBBON} from '../constants/ribbons'

export const isResponsive = (componentConfig: WidgetComponentConfig) => componentConfig.settings.responsive

export const getWidgetType = (componentConfig: WidgetComponentConfig) => componentConfig.settings.widgetType

export const getWidgetLayout = (componentConfig: WidgetComponentConfig) => componentConfig.settings.widgetLayout

export const getImageWidth = (componentConfig: WidgetComponentConfig) => componentConfig.settings.imageWidth

export const getImageGridPosition = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.imageGridPosition

export const getImageOpacity = (componentConfig: WidgetComponentConfig) => componentConfig.settings.imageOpacity

export const getImageRatio = (componentConfig: WidgetComponentConfig) => componentConfig.settings.imageRatio

export const getMobileImageRatio = (componentConfig: WidgetComponentConfig) => {
  if (componentConfig.settings.imageRatioMobile) {
    return componentConfig.settings.imageRatioMobile
  }
  if (isCardsLayout(componentConfig)) {
    return componentConfig.settings.imageRatio
  }
  return IMAGE_RATIO['16:9']
}

export const getImagePosition = (componentConfig: WidgetComponentConfig) => componentConfig.settings.imagePosition

export const getVerticalImagePosition = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.verticalImagePosition

export const getCountdownMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.countdownMarginBottom

export const getDateAndLocationMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.dateAndLocationMarginBottom

export const getDescriptionMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.descriptionMarginBottom

export const getTitleMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.titleMarginBottom

export const getDateMarginBottom = (componentConfig: WidgetComponentConfig) => componentConfig.settings.dateMarginBottom

export const getRsvpMarginBottom = (componentConfig: WidgetComponentConfig) => componentConfig.settings.rsvpMarginBottom

export const getLocationMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.locationMarginBottom

export const getAlignment = (componentConfig: WidgetComponentConfig) => componentConfig.settings.alignment

export const getContentAlignment = (componentConfig: WidgetComponentConfig) => componentConfig.settings.contentAlignment

export const getOneButtonWidth = (componentConfig: WidgetComponentConfig) => componentConfig.settings.oneButtonWidth

export const getButtonHeight = (componentConfig: WidgetComponentConfig) => componentConfig.settings.buttonHeight

export const getButtonBorderRadius = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.buttonsBorderRadius

export const getRoundedButtonBorderRadius = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.roundedButtonsBorderRadius

export const getListImageLayout = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listImageLayout

export const getListImagePosition = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.listImagePosition

export const getListImageOpacity = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listImageOpacity

export const getListAlignment = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listAlignment

export const getListImageWidth = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listImageWidth

export const getListDividerWidth = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listDividerWidth

export const getListStripWidth = (componentConfig: WidgetComponentConfig, mobile = false): number => {
  const {listStripWidth, listStripWidthMobile} = componentConfig.settings
  if (mobile) {
    if (listStripWidthMobile || listStripWidthMobile === 0) {
      return listStripWidthMobile + 10
    }
    return listStripWidth <= 12 ? 12 : listStripWidth
  }
  return listStripWidth
}

export const getListLocationAndDateFormat = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.listLocationAndDateFormat

export const getcardLocationAndDateFormat = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.cardLocationAndDateFormat

export const getListButtonStyle = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listButtonStyle

export const getCardsPerRow = (componentConfig: WidgetComponentConfig) => componentConfig.settings.cardsPerRow

export const getCardMargins = (componentConfig: WidgetComponentConfig) => componentConfig.settings.cardMargins

export const getCardBorderWidth = (componentConfig: WidgetComponentConfig) => componentConfig.settings.cardBorderWidth

export const getSideBySideLayoutMargins = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.sideBySideLayoutMargins

export const getCalendarWeekStartDay = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.calendarWeekStartDay

export const getCalendarCellBorderWidth = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.calendarCellBorderWidth

export const getTodayButtonStyle = (componentConfig: WidgetComponentConfig) => componentConfig.settings.todayButtonStyle

export const getVerticalDividerWidth = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.verticalDividerWidth

export const getVerticalDividerHeight = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.verticalDividerHeight

export const getHorizontalDividerWidth = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.horizontalDividerWidth

export const getHorizontalDividerHeight = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.horizontalDividerHeight

export const getCompactHorizontalDividerWidth = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.compactHorizontalDividerWidth

export const getCompactHorizontalDividerHeight = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.compactHorizontalDividerHeight

export const getListLayout = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listLayout

export const isMultiEventWidget = (componentConfig: WidgetComponentConfig) =>
  getWidgetType(componentConfig) === WIDGET_TYPE.LIST

export const isSingleEventWidget = (componentConfig: WidgetComponentConfig) =>
  getWidgetType(componentConfig) === WIDGET_TYPE.SINGLE

export const isHorizontalLayout = (componentConfig: WidgetComponentConfig) =>
  isSingleEventWidget(componentConfig) && getWidgetLayout(componentConfig) === WIDGET_LAYOUT.FULL

export const isVerticalLayout = (componentConfig: WidgetComponentConfig) =>
  isSingleEventWidget(componentConfig) && getWidgetLayout(componentConfig) === WIDGET_LAYOUT.VERTICAL

export const isButtonLayout = (componentConfig: WidgetComponentConfig) =>
  isSingleEventWidget(componentConfig) && getWidgetLayout(componentConfig) === WIDGET_LAYOUT.BUTTON_ONLY

export const isBackgroundLayout = (componentConfig: WidgetComponentConfig) =>
  isSingleEventWidget(componentConfig) && getWidgetLayout(componentConfig) === WIDGET_LAYOUT.BACKGROUND

export const isRightLayout = (componentConfig: WidgetComponentConfig) =>
  isHorizontalLayout(componentConfig) && componentConfig.settings.imagePosition === HORIZONTAL_IMAGE_POSITION.RIGHT

export const isBottomLayout = (componentConfig: WidgetComponentConfig) =>
  isVerticalLayout(componentConfig) && componentConfig.settings.verticalImagePosition === VERTICAL_IMAGE_POSITION.BOTTOM

export const isSideBySideLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) && getListLayout(componentConfig) === LIST_LAYOUT.SIDE_BY_SIDE

export const isListLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) && getListLayout(componentConfig) === LIST_LAYOUT.LIST

export const isCardsLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) &&
  (getListLayout(componentConfig) === LIST_LAYOUT.NEW_GRID || getListLayout(componentConfig) === LIST_LAYOUT.GRID)

export const isCalendarLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) && getListLayout(componentConfig) === LIST_LAYOUT.CALENDAR

export const isLabelEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showLabels

export const isImageEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showImage

export const isCountdownEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showCountdown

export const isTitleEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showTitle

export const isDescriptionEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showDescription

export const isDateEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showDate

export const isLocationEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showLocation

export const isShareOptionsEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showShareOptions

export const isAnyRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  isSoldOutRibbonEnabled(componentConfig, mobile) ||
  isJoinWaitlistRibbonEnabled(componentConfig, mobile) ||
  isRsvpClosedRibbonEnabled(componentConfig, mobile) ||
  isRecurringRibbonEnabled(componentConfig, mobile) ||
  isOfferRibbonEnabled(componentConfig, mobile)

export const isAnyStatusRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  isSoldOutRibbonEnabled(componentConfig, mobile) ||
  isJoinWaitlistRibbonEnabled(componentConfig, mobile) ||
  isRsvpClosedRibbonEnabled(componentConfig, mobile)

/**
 * @deprecated Should only be used as legacy fallback for more specific ribbons
 */
export const isStatusRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRibbon')

export const isOfferRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showOfferRibbon')

export const isSoldOutRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showSoldOutRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isJoinWaitlistRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showJoinWaitlistRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isRsvpClosedRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRsvpClosedRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isRecurringRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRecurringRibbon')

export const isRibbonEnabled = (ribbon: RIBBON, componentConfig: WidgetComponentConfig, mobile: boolean) => {
  return {
    [RIBBON.SOLD_OUT]: isSoldOutRibbonEnabled,
    [RIBBON.RECURRING]: isRecurringRibbonEnabled,
    [RIBBON.JOIN_WAITLIST]: isJoinWaitlistRibbonEnabled,
    [RIBBON.MEMBERSHIP]: isOfferRibbonEnabled,
    [RIBBON.RSVP_CLOSED]: isRsvpClosedRibbonEnabled,
  }[ribbon](componentConfig, mobile)
}

export const isMembersEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showMembers

export const isCompactDividersEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showCompactDividers

export const isFullDividersEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showFullDividers

export const isHorizontalDividersEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showHorizontalDividers

export const isRsvpTitleEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showRSVPTitle

export const isListMainTitleEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowMainTitle')

export const isListDateEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowDate')

export const isListVenueNameEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.listShowVenueName

export const isListFullDateEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowFullDate')

export const isListLocationEnabled = (componentConfig: WidgetComponentConfig, mobile) =>
  getValue(componentConfig, mobile, 'listShowLocation')

export const isListDescriptionEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowDescription')

export const isListSocialShareEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowSocialShare')

export const isListImageEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowImage')

export const isListMembersElementEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowMembers')

export const isListAdditionalInfoEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowAdditionalInfo')

export const isListImageLayoutRight = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.listImageLayout === LIST_IMAGE_LAYOUT.RIGHT

export const isCompactDateAndLocation = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.COMPACT

export const isHorizontalDateAndLocation = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.HORIZONTAL

export const isVerticalDateAndLocation = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.VERTICAL

export const isHollowButton = (componentConfig: WidgetComponentConfig) =>
  [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.buttonsStyle)

export const isRoundedButton = (componentConfig: WidgetComponentConfig) =>
  [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.buttonsStyle)

export const isListHollowButton = (componentConfig: WidgetComponentConfig) =>
  [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.listButtonStyle)

export const isListRoundedButton = (componentConfig: WidgetComponentConfig) =>
  [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.listButtonStyle)

export const isTodayButtonHollow = (componentConfig: WidgetComponentConfig) =>
  [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.todayButtonStyle)

export const isTodayButtonRounded = (componentConfig: WidgetComponentConfig) =>
  [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.todayButtonStyle)

export const isFitImage = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.imageScaling === IMAGE_SCALING.FIT

const getValue = (componentConfig: WidgetComponentConfig, mobile: boolean, key: string) => {
  const mobileKey = key + 'Mobile'
  const value = componentConfig.settings[key]
  const mobileValue = componentConfig.settings[mobileKey]
  if (mobile) {
    return mobileValue ?? value
  }
  return value
}
